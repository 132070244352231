<template>
    <div>
        <title-bar :title="$t('navigations.partRequests')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:cwo="data">
                    {{ data.value.cwo.referenceNumber }}
                </template>
                <template v-slot:station="data">
                    {{ data.value.cwo.station.name }}
                </template>
                <template v-slot:partNumber="data">
                    {{ data.value.partNumber }}
                </template>
                <template v-slot:quantity="data">
                    {{ data.value.quantity }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:remark="data">
                    {{ data.value.remark }}
                </template>
                <template v-slot:stock="data">
                    <b-badge :variant="data.value.stockQuantity > data.value.quantity ? 'success' : 'danger'">
                        {{ data.value.stockQuantity }}
                    </b-badge>
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="statusClass(data.value.cwo.status)">{{ data.value.cwo.status }}</b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <custom-btn icon="truck" title="Deliveries"
                                    :path="{ path: '/deliveries/add', query: { station: data.value.cwo.station.name, quantity: data.value.quantity, remark: data.value.remark, partNumber: data.value.partNumber }}"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import CustomBtn from "@/components/Buttons/CustomBtn";

export default {
    name: "AdminPartRequestsListing",
    props: {
        widget: {type: Boolean, default: false}
    },    
    components: {
        CustomBtn,
        TableLite,
        TablePagination,
        TableActions,
        TitleBar
    },

    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            sortable: {
                order: "fromDate",
                sort: "asc",
            },
            columns: [
                {
                    label: this.$t("navigations.cwo"),
                    field: "cwo",
                    sortable: true,
                },
                {
                    label: this.$t("navigations.station"),
                    field: "station",
                    sortable: true,
                },
                {
                    label: this.$t("forms.partNumber"),
                    field: "partNumber",
                    sortable: true,
                },
                {
                    label: this.$t("forms.quantity"),
                    field: "quantity",
                    sortable: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.remark"),
                    field: "remark",
                    sortable: false,
                },
                {
                    label: this.$t("forms.inStock"),
                    field: "stock",
                    sortable: false,
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            params['cwo.status'] = ['OPEN', 'CONFIRMED', 'DEFERRED'];
            this.$PartRequests.getCollection({params}, 'part_request_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === "station") {
                this.sortable.order = "cwo.station.name";
            }
            if (order === "status") {
                this.sortable.order = "cwo.status";
            }

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        statusClass(status) {
            let className = 'primary'
            if (status === 'CONFIRMED') {
                className = 'danger'
            }
            if (status === 'DEFERRED') {
                className = 'warning'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
